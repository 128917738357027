/************************************************
* GERAL
*************************************************/
body{
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
}
h1, h2, h3, h4, h5, h6{
    text-align: center;
    margin-top: 2px;
    margin-bottom: 4px;
}

.desabilitado {
    pointer-events:none;
    opacity:0.6;
}

a,
a:hover,
a:focus,
a:visited,
a:active,
i,
i:hover,
i:focus,
i:visited,
i:active{
	text-decoration: none;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"],
select[type="text"].novolayout {
    line-height: 1.42857143 !important;
    height: 100%;
}

.container-fluid{
    padding: 0;
}


/* CALENDARIO by FF */
.mes {
    padding: 10px 10px;
    width: 100%;
    background: #1abc9c;
    text-align: center;
}

.mes ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.mes ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.semana {
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
}

.semana li{
    width: 13.6%;
    display: inline-block;
    list-style-type: none;
    color: #666;
    text-align: center;
}

.dias {
    padding: 10px 0;
    background: #eee;
    margin: 0;
}

.dias li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    font-size:12px;
    color: #777;
}
.dias li .feriado {
    padding: 5px;
    background: #1abc9c;
    color: white !important
}

.dias li .cancelado-ns {
    padding: 5px;
    background: red;
    color: white !important
}

.dias li .cancelado-cliente {
    padding: 5px;
    background: blue;
    color: white !important
}

.dias li .trabalho {
    padding: 5px;
    background: black;
    color: white !important
}

.legenda {
    padding: 0;
    background: #eee;
    margin: 0;
}

.legenda li {
    margin-left: 5px;
    margin-right: 5px;
    list-style-type: none;
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    background: #eee;
    margin: 0;
}

.legenda li .feriado {
    padding: 5px;
    background: #1abc9c;
    color: white !important
}

.legenda li .cancelado-ns {
    padding: 5px;
    background: red;
    color: white !important
}

.legenda li .cancelado-cliente {
    padding: 5px;
    background: blue;
    color: white !important
}

.legenda li .trabalho {
    padding: 5px;
    background: black;
    color: white !important
}


/************************************************
* ULS
*************************************************/
.ul-reset{
    list-style: none;
    margin: 0;
    padding: 0;
}


/************************************************
* HELPERS
*************************************************/
.item-click{
    cursor: pointer;
}
.overflow {
    overflow-y: auto !important;
    overflow-x: auto !important;
}
.overflow-x {
    overflow-x: scroll !important;
}
.overflow-y {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}
.overflow-yx {
    overflow-y: hidden;
    overflow-x: auto;
}
.d-block{
    display: block !important;
}
.d-inline{
    display: inline;
}
.b-radius-6{
    border-radius: 6px;
}
.borda-xs{
    border: 2px solid;
}
.borda-verde{
    border-color: #488EA0;
}

.box-shadow{
    box-shadow: 0px  0px 5px 2px #888888;
}

.p-0-all
{
    padding: 0 !important;
}
.p-4-all{
    padding: 4px !important;
}
.p-6-all{
    padding: 6px !important;
}
.p-10-all{
    padding: 10px;
}
.p-20-all{
    padding: 20px;
}
.p-30-all{
    padding: 30px;
}
.p-40-all{
    padding: 40px;
}
.p-50-all{
    padding: 50px;
}
.p-10-h{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.p-80-h{
    padding-left: 80px !important;
    padding-right: 80px !important;
}
.p-60-h{
    padding-left: 60px !important;
    padding-right: 60px !important;
}
.p-4-v{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.p-6-v{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.p-10-v{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.p-50-v{
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}
.p-4-b{
    padding-bottom: 4px !important;
}
.p-10-b{
    padding-bottom: 10px !important;
}
.p-15-b{
    padding-bottom: 15px !important;
}
.p-10-r{
    padding-right: 10px;
}

.m-auto{
    margin: 0 auto;
}
.m-0-all{
    margin: 0 !important;
}
.m-2-all{
	margin: 2px !important;
}
.m-6-all{
	margin: 6px !important;
}
.ma-md{
    margin: 30px;
}
.m-6-b{
    margin-bottom: 6px !important;
}
.m-10-b{
    margin-bottom: 10px !important;
}
.m-20-b{
    margin-bottom: 20px !important;
}
.m-30-b{
    margin-bottom: 30px !important;
}
.m-0-b{
    margin-bottom: 0 !important;
}
.m-4-t{
    margin-top: 4px !important;
}
.m-10-t{
    margin-top: 10px !important;
}
.m-20-t{
    margin-top: 20px !important;
}
.m-30-t{
    margin-top: 30px !important;
}

/* Altura Pixels */
.m-height-40{
    min-height: 40px;
}
.height-20{
    height: 20px;
}
.height-30{
    height: 30px;
}
.height-40{
    height: 40px;
}
.height-50{
    height: 50px;
}
.height-60{
    height: 60px;
}
.height-80{
    height: 80px;
}

/* Altura Porcentagem */
.height-100-p{
    height: 100% !important;
}

/* Altura Root Element */
.height-30-r{
    height: 30rem
}
.height-40-r{
	height: 40rem;
}

/* Largura Pixels */
.width-14{
    width: 14px;
}
.width-25 {
    width: 25px;
}
.width-30 {
    width: 30px;
}
.width-50 {
    width: 50px;
}
.width-100 {
    width: 100px;
}
.width-150 {
    width: 150px;
}
.width-200 {
    width: 200px;
}
.width-220{
    width: 220px;
}
.width-250{
    width: 250px;
}
.width-580{
	width: 580px;
}
.width-620{
	width: 620px;
}
.width-820{
	width: 820px;
}
.width-1342{
	width: 1342px;
}
.width-1620{
	width: 1620px !important;
}
.width-1642{
	width:1642px;
}
.width-1740{
	width: 1740px;
}
.width-2000{
	width: 2000px;
}

/* Largura Porcentagem */
.width-5-p{
    width: 5%;
}
.width-6-p{
    width: 6%;
}
.width-14-p{
    width: 14%;
}
.width-16-p{
    width: 16%;
}
.width-32-p{
    width: 32% !important;
}
.width-48-p{
    width: 48% !important;
}
.width-50-p{
    width: 50%;
}
.width-80-p{
    width: 80%;
}
.width-100-p{
    width: 100% !important;
	border:2px solid black;
}
.width-100-pc {
	width: 100% !important;
}

.cursor:hover{
    cursor: pointer;
}

.font-size-12{
	font-size: 12px;
}

.redondo{
    border-radius: 100px
}
/************************************************
* ANIMATE
*************************************************/
@keyframes change {
  from {
      height: 100%;
  } to {
      height: 0;
  }
}
ul.ng-hide {
  animation: 0.5s change;
}
.ng-hide{
    animation: 0.5s change;
}
/************************************************
* LIST STRIPPED
*************************************************/
.list-stripped li:nth-of-type(odd){
    background: #edf9f9;
}
/************************************************
* BOTÕES
*************************************************/
.btn{
    outline: none;
}
.btn i.fa{
    /*margin: 0;*/
}
.btn-info{
    background-color: #53c3b7;
}
.btn-info:hover{
    background-color: #2f99a7;
}
.btn-primary{
    background-color: #2e6aff;
}
.btn-primary:hover{
    background-color: #2f3f97;
}
.btn-secondary{
    background-color: #eeeeee;
}
.btn-secondary:hover{
    background-color: #d8d6d6;
}
.btn-success{
    background-color: #5db75d;
}
.btn-success:hover{
    background-color: #47984b;
}
.btn-danger{
    background-color: #d9544f;
}
.btn-danger:hover{
    background-color: #a53d3a;
}
.btn-menu-job{
    background-color: #3997a9;
    color: #ffffff;
}
.btn-menu-job:hover{
    background-color: #277a8a;
}
.btn-outline{
    background-color: #ffffff;
}
.btn-outline:hover{
    background-color:transparent;
}
.btn-outline-novo{
    background-color: #ffffff;
}
.btn-selected{
	background-color: #2f99a7;
}
.btn-sm{
    min-width: 34px;
}
.btn-slim{
    padding: 4px 12px;
}
@media (max-width: 767px) {
    .btn-slim {
        padding: 10px 14px;
    }
}
.btn-right{
    margin-right: 4px;
}
.btn-right-10{
    margin-right: 10px;
}
.btn-footer-jobs{
    padding: 4px;
}
@media (max-width: 1200px) {
    .btn-footer-jobs {
        font-size: 12px;
        padding: 10px 14px;
    }
}
.btn-input-filtro {
	margin-top: -6px;
	padding: 4px 10px;
	margin-right: -9px;
	margin-left: -8px;
	border-radius: 0 4px 4px 0;
}
.btn-input-filtro-modificado {
	padding: 2px 10px;
	margin-right: -36px;
	border-radius: 0 4px 4px 0;
}
.btn-clip{
    padding: 3px 2px;
    margin-top: -6px;
    border-radius: 0 2px 2px 0;
}

/************************************************
* CORES
************************************************/
.bg-verde-escuro{
    background-color: #0c2d34 !important;
}
.bg-verde-medio{
    background-color: #4B97A2 !important;
}
.bg-verde{
    background-color: #53c3b7 !important;
}
.bg-verde-claro{
    background-color: #b6e7e3 !important;
}
.bg-cinza{
    background-color: #f3f3f5 !important;
}
.bg-branca{
    background-color: #ffffff !important;
}
.bg-vermelha{
    background-color: #d9544f !important;
}
.bg-vermelha-medio{
    background-color: #ff9c99 !important;
}
.bg-azul{
    background-color: #5380c3 !important;
}
.bg-laranja{
   background-color: #FFA347 !important;
}
.borda-0{
    border: none !important;
}
.borda-cinza{
    border: 1px solid #f3f3f5 !important;
}
.borda-cinza-escuro{
    border: 1px solid #909090 !important;
}
.borda-vermelha{
    border: 1px solid #d9544f !important;
}
.borda-verde{
    border: 1px solid #5db75d !important;
}

.borda-cor-verde-medio {
    border-color: #4B97A2 !important;
}
.borda-cor-verde{
   border-color: #5db75d !important;
}
.borda-cor-laranja{
   border-color: #FFA347 !important;
}
.cor-branca{
    color: #ffffff;
}
.cor-vermelha{
    color:  #d9544f !important;
}
.cor-verde{
    color: #5db75d !important;
}
.cor-verde-disabled{
    color: #5db75d !important;
}
.cor-cinza{
    color: #404041;
}
.cor-verde-medio{
    color: #4B97A2 !important;
}
.cor-laranja{
    color: #FFA347 !important;
}
.cor-laranja-escura{
    color: #ed7d31;
}
.negrito{
    font-weight: bold;
}
.bg-primary{
    color: #ffffff;
    background-color: #5db75d;
}
.bg-danger{
    color: #ffffff;
    background-color: #d9544f !important;
}

.bg-warning{
    color: #000;
    background-color: #ffc107 !important;
}

.grayscale{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
/************************************************
* ESTADOS
************************************************/
.selecionado {
    background-color: #c1c1c1 !important;
    font-weight: bold;
}
.sucesso{
    background-color: #5cb85c !important;
}
.texto-sucesso{
    color: #5cb85c !important;
}
.erro{
    background-color: #d9534f !important;
}
.texto-erro{
    color: #d9534f !important;
}


/************************************************
* CURSORS
************************************************/
.db-click{
    cursor: pointer;
}

/************************************************
* SECTIONS
************************************************/
#principal{
    background-color: #ffffff;
    padding-top: 0px;
}


/************************************************
* HEADER
************************************************/
#header{
    background-color: #0c2d34;
    color: #ffffff;
}
#header .usuario-logado-img{
    height: 60px !important;
    margin: 5px
}
#header .usuario-logado-info {
    margin-top: 8px;
}
#header .usuario-logado-info h5{
	text-align: left;
	margin: 5px 0 ;
}
#header .usuario-logado-info p{
    text-align: left;
    margin: 0 0 -4px;
    padding: 0;
}
#header .usuario-logado-mais{
    margin: 34px 0 0 -30px;
}
@media (max-width: 767px) {
    #header .usuario-logado-mais {
        z-index: 99 !important;
    }
}

#header .usuario-logado-mais .dropdown-menu{
    left: unset;
    right: 0;
    top: 33px;
    background-color: #0c2d34;
    border-radius: 0 0 2px 2px;
    border-top: 0;
}
#header .usuario-logado-mais .dropdown-menu>li>a{
    color: #ffffff;
}
#header .usuario-logado-mais .dropdown-menu>li>a:hover{
    background-color: #134650;
}
@media (max-width: 767px) {
    #header .usuario-logado-mais .dropdown-menu {
        left: 0;
    }
}
#header .usuario-logado-mais #usuario-logado-mais{
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
    color: #000000;
    height: 14px;
}
#header .usuario-logado-mais #usuario-logado-mais .caret{
    margin-top: -12px;
}


/************************************************
* LOGIN
************************************************/
.bg-login{
    background-color: #0B0B0D;
    background-image: url("../img/portal_login_bg-min.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#principal-login input[type='text'],
#principal-login input[type='password']{
    height: 36px;
    text-align: center;
    border-radius: 1px;
}
.btn-login{
    color: #ffffff;
    background-color: #F90;
    border-radius: 1px;
    padding: 6px;
}
.btn-login:hover{
    color: #f7f7f7;
    background-color: #e08700;
}
.btn-esqueci-senha{
	color: #ffffff;
    background-color: #5380c3;
    border-radius: 1px;
    padding: 6px;
}
.btn-esqueci-senha:hover{
    color: #f7f7f7;
    background-color: #6b8fc5;
}
.btn-criar-conta{
    color: #F90;
}
.btn-criar-conta:hover{
    text-decoration: underline;
}

/************************************************
* MENU
************************************************/
.menu{
    background-color: #e6e7e9;
    padding: 0px 0 14px;
    margin-bottom: -80px;
}
.nav-side-menu {
    overflow: auto;
    font-size: 15px;
    font-weight: 200;
    position: relative;
    width: 100%;
    color: #e1ffff;
    z-index: 9;
}
.nav-side-menu .brand {
    background-color: #23282e;
    line-height: 50px;
    display: block;
    text-align: center;
    font-size: 14px;
}
.nav-side-menu .toggle-btn {
    display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    line-height: 35px;
    cursor: pointer;
}
.nav-side-menu li {
    padding-left: 0px;
    border-bottom: 1px solid #c6c7c9;
}
.nav-side-menu li a {
    text-decoration: none;
    color: #404042;
    display: block;
    width: 100%;
    padding-left: 0px;
}
.nav-side-menu li a i {
    padding-left: 26px;
    width: 20px;
    padding-right: 18px;
}
@media (max-width: 1500px) {
    .nav-side-menu li a i {
        padding-left: 10px;
        width: 20px;
        padding-right: 10px;
    }
}
.nav-side-menu li:hover {
    font-weight: 300;
    background-color: #ffffff;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.nav-side-menu li .active {
    font-weight: bold;
    background-color: #f3f3f5;
}
@media (max-width: 767px) {
    .nav-side-menu {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
    }
    .nav-side-menu .toggle-btn {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10 !important;
        padding: 3px;
        background-color: #ffffff;
        color: #000;
        width: 40px;
        text-align: center;
    }
    .brand {
        text-align: left !important;
        font-size: 22px;
        padding-left: 20px;
        line-height: 50px !important;
    }
}
@media (min-width: 767px) {
    .nav-side-menu .menu-list .menu-content {
        display: block;
    }
    .small-1
    {
        width: 6%;
    }
    .small-2
    {
        width: 13%;
    }
    .small-4
    {
        width: 30%;
    }
}


/************************************************
* FOOTER
************************************************/
#footer{
    position: absolute;
    bottom: 0;
    background-color: #0c2d34;
    width: 100%;
    color: #ffffff;
    text-align: center;
    z-index: 9;
}
#footer .redes-sociais{
    margin-top: 50px;
}
#footer .redes-sociais div{
    width: 20%;
    padding: 0;
}
#footer .redes-sociais i{
    font-size: 34px;
    border-radius: 8px;
    padding: 1px;
    background-color: #fff;
}
#footer .redes-sociais i.face{
    color: #345F90 ;
}
#footer .redes-sociais i.google{
    color: #E14842;
}
#footer .redes-sociais i.in{
    color: #0082AE;
}
#footer .redes-sociais .twitter{
    color: #00AFE8;
}
#footer .redes-sociais i.youtube{
    color: #CF2B2E;
}


/************************************************
* NOTIFICACOES
************************************************/
#footer .hr{
    padding: 0;
}
#footer p{
    padding: 0;
    margin: 0px;
    display: inline-block;
    font-size: 12px;
}
#footer .notificacoes{
    height: 9rem;
    width: 99.3%;
    overflow-y: auto;
    background-color: #ffffff;
}
#footer td{
    color: #000000;
    padding: 0 4px;
    text-align: left;
}
#footer .hr-pequena{
    width: 100%;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 4px;
    border-top: 1px solid #eee;
}

/* Notificacao flutuante */
#notificacoes {
    min-height: 50px;
    position: fixed;
    padding: 10px;
    top: 73px;
    right: 15px;
    z-index: 1041;
}

@media (max-width: 767px) {
	#notificacoes {
		top: 165px;
	}
}

/************************************************
* PANEL
************************************************/
.panel{
    border: 0px;
    margin-bottom: 0px;
}
.panel.panel-header,
.panel.panel-footer{
    background-color: #f3f3f5;
}
.panel-header>.img-responsive{
    margin: 0 auto;
}
.panel-body-no-panel-footer,
.panel-body{
    padding: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.panel-footer{
    min-height: 50px !important;
}
@media (max-width: 767px) {
    .panel-footer {
        min-height: 64px !important;
    }
}
#panel-body-big-header{
    overflow-y: scroll;
    overflow-x: hidden;
}


/************************************************
 *FILTRO
************************************************/
.filtro{
    padding: 4px 15px;
}
.filtro .form-group{
    margin: 0;
}
.filtro #str_filtro{
    display: none;
}
.btn-filtro{
    margin-top: 22px;
}

/* Filtro lista job*/
.filtro-lista-job .lista-rapida .lista-rapida-btns{
	text-align: right;
	position: relative;
	z-index: 9;
}
.filtro-lista-job .lista-rapida .lista-rapida-btns .btn {
	width: 134px;
}
.filtro-lista-job .lista-rapida .tipo_filtro{
	 position: relative;
     display: inline-flex;
     margin-top: 20px;
}
.filtro-lista-job .lista-rapida .tipo_filtro .tipo_filtro_item{
	padding: 4px 0;
}
.filtro-lista-job .lista-rapida .tipo_filtro .switch{
	margin: 0 4px;
}

@media (max-width: 767px) {
	.filtro-lista-job .lista-rapida .lista-rapida-btns label {
		display: block;
		text-align: center;
	}
	.filtro-lista-job .lista-rapida .lista-rapida-btns .btn {
		margin-bottom: 8px;
	}
	.filtro-lista-job .lista-rapida .tipo_filtro{
		display: table;
		margin: 10px auto;
	}
}

/************************************************
* FORMULARIOS
************************************************/
.form{
    overflow-y: auto;
    overflow-x: hidden;
    height: 76vh;
}
@media screen and (max-width: 1441px) and (max-height: 781px){
    .form{
        height: 68vh;
    }
}
label{
    margin-bottom: 2px;
}
textarea{   
    resize: none;
}

.form-control,
input[disabled]{
    height: 30px;
    padding: 5px 12px;
}

.form-cabecalho{
    color: #ffffff;
    padding: 6px 0 4px 0;
    font-weight: bold;
}
.form-btns{
    margin-top: 10px;
}


/* VALIDAÇÕES */
.input-group{
    padding-top: 1px;
    padding-left: 1px;
}
.input-group>input,
.input-group>select,
.input-group>textarea,
.input-group>span{
    border: none;
    background-color: #ffffff;
}
.input-error{
    border-radius: 4px;
    border: 1px solid #d9544f !important;
}
.input-error .tooltip .tooltip-inner{
    background-color: #d9544f !important;
}
.input-error .tooltip > .tooltip-arrow {
    border-bottom-color: #d9544f !important;
}
.input-success{
    border-radius: 4px;
    border: 1px solid #5db75d !important;
}
.input-success-disabled{
    border-radius: 4px;
    border: 1px solid #5db75d !important;
}
.input-group>.input-group-addon{
	padding: 6px 0 0 0;
}
.input-group>.input-group-addon i{
	padding: 0px 5px;
}
.valor-job-destado{
    border-radius: 4px;
    border: 2px solid blue !important;
}



/* hack para diretiva ui-select */
.ui-select-container .ui-select-search,
.ui-select-bootstrap .ui-select-toggle {
    border: none;
    max-width: 569px;
}
@media screen and (max-width: 1441px) and (max-height: 781px){
    .ui-select-container .ui-select-search,
    .ui-select-bootstrap .ui-select-toggle {
        border: none;
        max-width: 404px;
    }
}
.ui-select-bootstrap>.ui-select-choices, .ui-select-bootstrap>.ui-select-no-choice
{
    width: auto !important;
    max-height: 150px !important;
}



/************************************************
 *TABELAS
************************************************/
table{
    margin-bottom: 0 !important;
}
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th{
    padding: 3px 4px;
    vertical-align: middle;
}

.table-job{
    border: 1px solid #ecf0f3;
    margin-bottom: 15px !important;
}
.table-job>tbody>tr>td,
.table-job>tbody>tr>th,
.table-job>tfoot>tr>td,
.table-job>tfoot>tr>th,
.table-job>thead>tr>td,
.table-job>thead>tr>th{
   vertical-align: top !important;
    border: 1px solid #ecf0f3;
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #edf9f9;
}

/* table lista metade */
.table-lista-metade{
    height: 50%;
    overflow-y: auto;
}
/************************************************
 * CARD
************************************************/
.card{
	margin: 10px 0;
    padding: 20px 10px 10px;
}
.card_hover:hover{
    background-color: #2f99a7 !important;
}
/************************************************
 * JOBS
************************************************/
.jobs-timeline{
    margin-left: 0;
    margin-right: 0;
    width: 99%;
}
.jobs-timeline .div-container{
    display: inline-flex;
    padding: 0px;
    height: 60px;
    width: 9%;
}
.jobs-timeline .div-container .btn-content{
    display: block;
    background-color: #3997a9;
    padding: 4px;
    width: 100%;
    height: 58px;
    border: 0;
    outline: none;
}
.jobs-timeline .div-container .btn-content:hover {
    background-color: #277a8a;
}
.jobs-timeline .div-container .btn-content:disabled{
    background-color: #F1F1F2;
}
.jobs-timeline .div-container .btn-content:disabled h4,
.jobs-timeline .div-container .btn-content:disabled i{
    color: #989898;
}
.jobs-timeline .div-container .btn-content:disabled i {
    color: #989898;
}
.jobs-timeline .div-container .btn-content h4{
    color: #fff;
    margin: 0px;
	font-size: 13px;
}
@media screen and (max-width: 1441px) and (max-height: 781px){
    .jobs-timeline .div-container .btn-content h4{
        font-size: 12px;
    }
}
.jobs-timeline .div-container .btn-content i{
    margin: 6px 0 0;
    color: #ffffff;
    font-size: 17px;
}

/* Jobs timeline setas */
.jobs-timeline .div-container .btn-content .arrow-um {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 28px solid transparent;
    border-left: 20px solid #3997a9;
    position: absolute;
    z-index: 9;
    float: right;
    right: -20px;
    top: 0px;
}
.jobs-timeline .div-container .btn-content:hover .arrow-um{
    border-left-color: #277a8a;
}
.jobs-timeline .div-container .btn-content:disabled .arrow-um{
    border-left-color: #F1F1F2;
}
.jobs-timeline .div-container .btn-content .arrow-container {
    position: relative;
    width: 0px;
    top: -12px;
    left: -24px;
}
.jobs-timeline .div-container .btn-content .arrow-container-duas-linhas {
    top: -5px;
}
.jobs-timeline .div-container .btn-content .arrow-container .arrow-dois {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 20px solid #3997a9;
    position: absolute;
    top: 28px;
}
.jobs-timeline .div-container .btn-content .arrow-container .arrow-tres {
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 20px solid #3997a9;
    position: absolute;
}
.jobs-timeline .div-container .btn-content:hover .arrow-container .arrow-dois,
.jobs-timeline .div-container .btn-content:hover .arrow-container .arrow-tres {
    border-right-color: #277a8a;
}
.jobs-timeline .div-container .btn-content:disabled .arrow-container .arrow-dois,
.jobs-timeline .div-container .btn-content:disabled .arrow-container .arrow-tres {
    border-right-color: #F1F1F2;
}
.jobs-timeline .div-container .span{
    width: 34px;
}

/* JOBS Outras Ações */
.jobs-outras-acoes{
    margin: 6px 6px 6px 0;
    position: relative;
    display: inherit;
}
.jobs-outras-acoes p{
    padding: 4px 10px 0 0;
    margin: 0;
    display: inline-block;
}
.jobs-outras-acoes div{
    margin-right: 10px;
    display: inline-block;
}
.jobs-outras-acoes button,
.jobs-outras-acoes .lista-jobs a{
    border-radius: 2px;
    padding: 3px 6px;
    border:0;
    outline: none;
	margin-bottom: 4px;
}
.jobs-outras-acoes button:disabled{
    background-color: #F1F1F2;
    color: #989898;
}

@media (max-width: 767px) {
	 .jobs-timeline .div-container {
        margin-bottom: 6px;
    }

    .jobs-timeline .div-container .btn-content .arrow-container {
        top: -11px;
        left: -23px;
    }

	#btn_associa_doutor{
		margin-right: inherit;
	}
	#btn_associa_doutor .arrow-container,
	#btn_anexa_arquivo .arrow-container {
		top: -11px !important;
	}

	.jobs-timeline-doutor #btn_inicio_deslocamento{
		margin-left: -7px;
	}
	.jobs-timeline-doutor #btn_anexa_arquivo .arrow-container{
        top: -12px !important;
    }

    .jobs-timeline .div-container {
        width: 32%;
    }
    .jobs-timeline-doutor .div-container {
        width: 48%;
    }

    .jobs-outras-acoes p {
        width: 100%
    }

    .jobs-outras-acoes div {
        width: 49%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .jobs-outras-acoes button {
        width: 100%;
        padding: 14px 6px;
    }

    #panel-body-big-header {
        height: 100% !important;
        overflow: auto;
    }

    #panel-body-mobile
    {
        height: 400px;
    }

    th,
    td {
        width: 50%;
        height: 30px;
    }
}

@media (max-width: 450px) {
	.jobs-timeline .div-container .btn-content .arrow-container {
        top: -12px;
    }
	#btn_associa_doutor .arrow-container,
	#btn_anexa_arquivo .arrow-container {
		top: -12px !important;
	}
	.jobs-timeline .div-container .btn-content h4 {
    	font-size: 10px !important;
	}
}

/************************************************
* MODAL JOBs
************************************************/
.table-modal,
.table-modal-md,
.table-modal-xl,
.table-modal-xxl{
    width: 100%;
}
@media (max-width: 767px) {
    .table-modal{
        width: 700px;
        overflow: scroll;
    }
    .table-modal-md {
        width: 1000px;
        overflow: scroll;
    }
    .table-modal-xl{
        width: 1200px;
        overflow: scroll;
    }
    .table-modal-xxl{
        width: 1400px;
        overflow: scroll;
    }
}

/************************************************
* DASHBOARD
************************************************/
.mapa {
    width: 100%;
    height: 100%;
}
.mapa-legenda {
    position: absolute !important;
    bottom: 0 !important;
    right: 0px;
    width: 100%;
    background-color: #fff;
    display: none;
    z-index: 9999999 !important;
}
.mapa-legenda>div{
    padding: 2px 4px;
    margin: 0 auto;
}
.gmnoprint {
    bottom: 130px !important;
}


.switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 26px;
}
.switch input {display:none;}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 8px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #53c3b7;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/************************************************
* DASHBOARD DOUTOR
************************************************/

#jobs-periodo .circle,
#ganho-periodo .circle{
    border: 13px solid transparent;
    border-radius: 50%;
    width: 60%;
    height: 140px;
    padding: 36px 0;
    font-size: 28px;
    font-weight: bold;
    margin: 0 auto;
    text-align: center;
}
@media screen and (max-width: 1441px) and (max-height: 781px) {
	#jobs-periodo .circle,
	#ganho-periodo .circle {
		width: 82%;
	}
}
@media screen and (max-width: 689px) {
	#jobs-periodo .circle,
	#ganho-periodo .circle{
		height: 200px;
    	width: 56%;
		padding: 68px 0;
	}
}

#ganho-periodo span{
    width: 160px;
    display: block;
    color: #ffffff;
	padding: 2px 4px;
    text-align: right;
    margin-bottom: 4px;
}

/************************************************
* COMPETECIAS
************************************************/
.competencia-descricao {
    margin-top: -19.7vh
}
@media screen and (max-width: 1441px) and (max-height: 781px){
    .competencia-descricao {
        margin-top: -22vh
    }
}
.competencia-adicina-btn-mobile{
	font-size: 25px;
	padding: 6px 10px 6px 0;
	margin-top: 0px;
}

/************************************************
 *DOUTORES
************************************************/
#termo,
.termo{
    padding: 4px 15px;
}

#section-doutor-candidata ol li {
    font-size: 16px;
    padding-bottom: 6px;
}

/************************************************
 *ESPECIALIDADES
************************************************/
.table-lista-especialidades .item-click{
	font-size: 16px;
}
.table-lista-especialidades .item-click i{
	font-size: 24px;
	padding: 4px 4px 0;
}

/************************************************
 *RELATORIOS
************************************************/
#relatorio i{
   font-size: 130px;
}
.relatorio_margem{
    height: 24vh
}
.relatorio_margem_li_um {
    font-weight: bold;
    margin: 0 0 1px;
    font-size: 16px;
}
.relatorio_margem_li_dois {
    margin: 0 0 1px;
    background-color: #EBEBEB;
    font-size: 16px
}
.relatorio_margem_li_tres {
    margin: 0 0 1px;
    font-size: 16px
}

.relatorio_lista_um li:nth-of-type(odd){
    background-color: #53c3b7;
    color: #ffffff;
}
.relatorio_lista_um li:nth-of-type(even){
    background-color: #88d4ca;
    color: #ffffff;
}
.relatorio_lista_dois li:nth-of-type(odd){
    background-color: #fdd9bf;
    color: #000000;
}
.relatorio_lista_dois li:nth-of-type(even){
    background-color: #feece0;
    color: #000000;
}
.relatorio_lista_tres li:nth-of-type(odd){
    background-color: #e6f5f3;
    color: #000000;
}
.relatorio_lista_tres li:nth-of-type(even){
    background-color: #ffffff;
    color: #000000;
}


/************************************************
 *CHART
************************************************/
.well {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}


/************************************************
 *INTEGRAÇÒES
************************************************/
.integracao_ico {
	position: relative;
	top: -24px;
	font-size: 20px;
}
.integracao_ico:hover i{
	font-size: 24px;
}

/************************************************
* MENSAGEM
************************************************/
.mensagem_status{
    text-transform: uppercase;
}
.mensagem_ico{
    font-size: 20px;
}


/************************************************
* LOADER
************************************************/
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #488EA0;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/************************************************
* MODAL
************************************************/
.modal-content{
    border-radius: 2px;
}
.modal-xl{
    width: 1200px;
}
.modal-xxl{
    width: 1400px;
}
.modal-title{
    font-weight: bold;
}


/************************************************
* UPLOAD DOCUMENTO
************************************************/
.seleciona_documento{
    min-height: 80px;
}
.seleciona_documento span{
    float: right;
    width: 75%;
    overflow: auto;
}


/************************************************
* TEMPLATE ERRO
************************************************/
.template-erro {
    margin-top: 26vh;
    height: 25rem;
}


/************************************************
* MOODLE HELP
************************************************/
.moodle_help {
	top: -5px;
	position: relative;
}

/************************************************
* RESETS
************************************************/
.daterangepicker.opensright:after,
.daterangepicker.opensright:before{
    right: 170px !important;
    left: auto !important;
}
.daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
	width: 664px !important;
	margin-right: 18px;
}
@media screen and (max-width: 1441px) and (max-height: 781px){
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
        width: 664px !important;
    }
    .daterangepicker.opensright:after,
    .daterangepicker.opensright:before{
        right: 132px !important;
        left: auto !important;
    }
}
.input-group-addon {
	padding: 6px 8px;
}

.multiSelect .checkBoxContainer{
	max-height: 400px;
    overflow-y: auto;
}
/************************************************
* PORTAL TESTE
************************************************/
.portal-teste-container{
	position: relative;
    height: 0;
    margin: 0 auto;
    text-align: center;
}
.portal-teste-container h3{
    background-color: red;
    display: inline-block;
    text-align: center;
    margin: 0px auto;
    padding: 8px;
    color: #ffffff;
    border-radius: 0 0 2px 2px;
}


/************************************************
* RESPONSIVIDADE GLOBAL
************************************************/
@media (max-width: 767px) {
    li{
		font-size: 20px !important;
    	padding-top: 8px !important;
    	padding-bottom: 8px !important;
    }
}

/************************************************
* NOVO LAYOUT
************************************************/
/* RESETS */
.no-border{
    border: 0 !important;
}
/* CORES */
.cor-verde-nova{
    color: #488EA0
}
/* BTNS */
.btn-degrade-laranja{ 
    background: linear-gradient(to right, #FF9D00, #FF9D00) !important;
    height: 40px;
    font-weight: bolder;
    border-radius: 100px; 
    color: #ffffff;
    padding: 10px 22px;
}
.btn-verde{ 
    background: #488EA0;
    height: 40px;
    font-weight: bolder;
    border-radius: 100px; 
    color: #ffffff;
    padding: 0 22px;
}

/* FORMS */
.input-group-novo {
    border: 1px solid #D8D8D8;
}
.input-group-novo,
.input-group-novo input,
.input-group-novo select {
    border-radius: 5px !important;
    height:40px !important;
    background-color: #FAFAFA;
}
.input-group-novo input[disabled]{
    height:40px !important;
}
.input-group-novo .form-control{
    padding: 5px 8px;
}
.form-group label{
    font-size: 12px;
    font-weight: 400;
}

.candidata {border-radius: 10px; }
.candidata ul{list-style: none; padding: 0px}
.candidata ul>li{margin-bottom: 30px}  
.candidata ul>li>div>img{width:40px; padding: 0; margin-right: 10px}
.candidata ul>li>div>p{display: inline;}


.candidato_supporter_cadastra{
    height: 100vh; 
    overflow: hidden;
    background-color: #ffffff;
    width: 100vw;
}
.candidato_supporter_cadastra .container_cadastra{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container_cadastra .header{
    justify-content: center;
    width: 350px;
}
.container_cadastra .body{
    background-color:#DBDEDE; 
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.container_cadastra .body form{
    height: 86.2vh;
    overflow-y: auto;
    padding-bottom: 40px; 
}
@media screen and (max-width: 824px) and (min-width: 420px){
    .container_cadastra .body form{
        height: 76.2vh !important;
    }
}

.container_cadastra .body h4{
    font-size: 24px;
    text-align: left; 
    color: rgba(0, 0, 0, .39) !important;
}
.container_cadastra .body hr{
    border-color: rgba(0, 0, 0, .39) !important;
}

/* DADOS PESSOAIS */
.dados_pessoais_cnpj_alerta{
    display: flex; 
    text-align: left;
    align-items:center; 
    border-radius: 4px;
}
.dados_pessoais_cnpj_alerta img{
    width: 50px;
    align-self:flex-end;
    height: 50px;
    margin-right:20px;
}

/* RADIO BUTTON */
.radiobutton{
    height: 42px;
    align-items: center;
}
.radiobutton .container {
    padding: 0;
    margin-bottom: 2px
}
.radiobutton .content {
    display: block;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.radiobutton .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.radiobutton .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
}
.radiobutton .container input:checked ~ .checkmark {
    background-color: #488EA0;
}
.radiobutton .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.radiobutton .container input:checked ~ .checkmark:after {
    display: block;
}
.radiobutton .container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}

/* AVALIACAO */
.star{
    font-size: 30px; 
    padding: 8px;
    color: #c0c0c0
}
.star-selected{
    color:#FAB941 !important
}
.btn-selected{
    color: #ffffff !important; 
    background-color:#4b97a2 !important
}
.visible{
    position: relative !important;
    display: block !important;
    visibility:visible;
}
.invisible{
    position: absolute;
    display: none;
}
